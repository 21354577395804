
















































import { Component, Vue, Prop } from "vue-property-decorator"
import ClassifiedAd from "~/models/ClassifiedAd"
import House from "~/models/House"
import { AdsHistory } from "~/models/House"
import UnassociatedAd from "@/components/house/UnassociatedAd.vue"

@Component({
  components: {
    UnassociatedAd,
    AssociationModal: () => import("@/components/modals/AssociationModal.vue"),
  },
})
export default class extends Vue {
  @Prop({ required: true }) house!: House
  randomAssociateKey = Math.random()

  get unassociatedAds() {
    let ads = this.house.history.filter((history) => history.agency_id == null)
    let houseHistories: Record<string, AdsHistory> = {}
    ads.forEach((ad) => {
      let standardAgencyName = ad.agency_name
        ?.replace(/[\n -]/g, "")
        .toLowerCase()
      if (!standardAgencyName) return

      Object.entries({ ...houseHistories }).forEach((houseHistory) => {
        if (houseHistory[1].agency_phone == ad.agency_phone) {
          standardAgencyName = houseHistory[0]
        }
      })

      if (!houseHistories[standardAgencyName])
        houseHistories[standardAgencyName] = {} as AdsHistory
      let adList: AdsHistory = houseHistories[standardAgencyName]
      if (!adList.agency_name) adList.agency_name = ad.agency_name
      if (!adList.agency_phone) adList.agency_phone = ad.agency_phone
      if (!adList.agent_name) adList.agent_name = ad.agent_name
      if (!adList.agent_phone) adList.agent_phone = ad.agent_phone
      if (!adList.scraps) adList.scraps = []
      let scrap: ClassifiedAd = new ClassifiedAd().assign({
        id: ad.id,
        created_at: new Date(Date.parse(ad.history[0].date)),
        scrap_name: ad.scrap_name,
        url: ad.url,
        active: ad.active,
      })
      adList.scraps.push(scrap)
      houseHistories[standardAgencyName] = adList
    })
    return houseHistories
  }

  openAssociateModale() {
    this.randomAssociateKey = Math.random()
    // @ts-ignore
    setTimeout(() => this.$refs.associateModal.open(), 0)
  }
}
