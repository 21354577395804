















































import { Component, Vue, Prop } from "vue-property-decorator"
import OcularPhone from "~/components/OcularPhone.vue"
import { AdsHistory } from "~/models/House"

@Component({
  components: {
    OcularPhone,
  },
})
export default class UnassociatedAd extends Vue {
  @Prop({ required: true }) adsHistory!: AdsHistory
}
