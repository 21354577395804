
































import { Component, Vue, Prop } from "vue-property-decorator"
import House from "~/models/House"
import AgencyCard from "@/components/agencies/AgencyCard.vue"
import UnassociatedAds from "@/components/house/UnassociatedAds.vue"

@Component({
  components: {
    AgencyCard,
    UnassociatedAds,
  },
})
export default class HouseAgencies extends Vue {
  @Prop({ required: true }) house!: House
  @Prop({ default: false }) loading!: boolean

  openAgencySheet(agencyId: number) {
    const query = this.$route.fullPath.slice(this.$route.path.length)
    this.$router.push(`${this.$route.path}/${agencyId}${query}`)
  }
}
